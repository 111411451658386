import React from 'react';
import { Buffer } from 'buffer';
import ContainerContents from '../../common/molecules/ContainerContents';
import ConseptSection from '../molecules/ConseptSection';
import ScrollDownButton from '../atoms/ScrollDownButton';
import ServiceSection from '../molecules/ServiceSection';
import NewsSection from '../molecules/NewsSection';
window.Buffer = Buffer;

/**
 * トップページコンテンツ群
 * @returns 
 */
const TopContent: React.FC = () => {
  return (
    <ContainerContents children={
      <>
        <NewsSection/>
        <ConseptSection />
        <ScrollDownButton />
        <ServiceSection />
      </>
    }/>
  );
};

export default TopContent;
